<template>
  <div id="home" class="amp-mask">
    <FloatingNav
      v-if="floatingNavActive"
      :floating-nav="homepage.floating_nav"
    />
    <cms-gallery
      v-if="galleryHasImagesOrVideo"
      :theme="galleryOptions"
      :gallery="homepage.gallery"
      :class="{ 'accent-styling': accentStyling }"
    />
    <div
      v-for="(container, index) in homepageContainers"
      :key="index"
      class="main"
    >
      <Container
        :container="container"
        :container-idx="container.containerIndex"
        :is-last-container="isLastContainer(index)"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import get from 'lodash/get';
import Container from './home_page/ViewContainer.vue';
import FloatingNav from './home_page/floating_nav/ViewFloatingNav.vue';

export default {
  name: 'ViewHomepage',
  components: {
    Container,
    FloatingNav,
  },
  computed: {
    ...mapState(['theme', 'homepage', 'accentStyling']),
    ...mapGetters(['getGlobalThemeSettings']),
    galleryHasImagesOrVideo() {
      const getItemType = (item) => item && item.split('?')[0].split('.').pop();
      const firstSlideIsVideo = ['webm', 'mp4'].includes(getItemType(this.homepage.gallery.slides?.[0]?.url));

      if (this.galleryOptions.use_video && this.galleryOptions.version === 'G2') {
        return firstSlideIsVideo;
      }
      // check if first image is video
      if (firstSlideIsVideo) {
        return false;
      }
      return this.homepage.gallery?.slides?.length >= 1;
    },
    galleryOptions() {
      return {
        ...this.theme.gallery.options,
        globalThemeSettings: this.getGlobalThemeSettings,
        hasFloatingNav: this.floatingNavActive,
      };
    },
    floatingNavActive() {
      return get(this.theme, 'floating_nav.options.active', false);
    },
    stickyFooterActive() {
      return get(this.theme, 'sticky_footer.options.active', false);
    },
    homepageContainers() {
      const containersWithIndex = this.theme.containers.map((container, idx) => ({
        ...container,
        containerIndex: idx,
      }));
      return containersWithIndex.filter((container) => !container.global);
    },
  },
  methods: {
    isLastContainer(index) {
      return index === this.theme.containers.length - 1;
    },
  },
};
</script>

<style scoped>
.main .container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
}
</style>
