<template>
  <div
    class="view-floating-nav"
  >
    <cms-floating-nav
      v-if="floatingNavSetUp"
      :theme="floatingNavOptions"
      :floating-nav="homepage.floating_nav"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';

export default {
  name: 'ViewFloatingNav',
  computed: {
    ...mapState(['theme', 'homepage', 'isThemePreview']),
    ...mapGetters({ getGlobalThemeSettings: 'getGlobalThemeSettings', getComponentTranslation: 'translation/getComponentTranslation' }),
    floatingNavOptions() {
      return {
        ...this.theme.floating_nav.options,
        translations: this.getComponentTranslation('floating_nav'),
        globalThemeSettings: this.getGlobalThemeSettings
      };
    },
    floatingNavSetUp() {
      return this.homepage.floating_nav && !isEmpty(this.homepage.floating_nav.buttons);
    }
  }
};
</script>
